/*
    Objects: List-ui
    ---
    Default markup for UI lists
*/

/**
 * The UI list object creates blocky list items with a keyline separator out of
 * a `ul` or `ol`.
 */

// Predefine the variables below in order to alter and enable specific features.
$list-ui-padding:         $base-spacing-unit !default;

$list-ui-border-width:    $border-width !default;   // settings.ui
$list-ui-border-style:    $border-style !default;   // settings.colors
$list-ui-border-color:    $alpha-ui-color !default; // settings.colors

.o-list-ui,
%o-list-ui,
    .o-list-ui__item,
    %o-list-ui__item {
        border: 0 $list-ui-border-style $list-ui-border-color;
    }

.o-list-ui,
%o-list-ui {
    margin:  0;
    padding: 0;
    list-style: none;
    border-top-width: $list-ui-border-width;

    > li {
        @extend %o-list-ui__item;
    }

}

    .o-list-ui__item,
    %o-list-ui__item {
        padding: $list-ui-padding;
        border-bottom-width: $list-ui-border-width;
    }
