/*
    Objects: Buttons
    ---
    Default markup for buttons
*/

/**
 * A simple button object.
 */

// Predefine the variables below in order to alter and enable specific features.
$btn-padding:         round($base-spacing-unit / 2) !default;
$btn-padding--small:  round($base-spacing-unit / 2) !default;
$btn-padding--large:  round($base-spacing-unit * 2) !default;
$btn-background:      $alpha-ui-color !default; // settings.colors
$btn-color:           $white !default;          // settings.colors
$btn-border-width:    $border-width !default;   // settings.ui
$btn-border-style:    $border-style !default;   // settings.colors
$btn-border-color:    $btn-background !default;
$btn-radius:          $radius !default;         // settings.ui

$enable-btn--small:   false !default;
$enable-btn--large:   false !default;
$enable-btn--full:    false !default;
$enable-btn--pill:    false !default;


// Here we set a variable assuming that `box-sizing: border-box;` is not set
// globally. If it has been previously been defined, the following variable will
// be overriden and will be set to `true`.
$global-border-box: false !default;


/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 * 6. Fixes odd inner spacing in IE7.
 * 7. Subtract the border size from the padding value so that buttons do not
 *    grow larger as we add borders.
 */
.o-btn,
%o-btn {
    display: inline-block; /* [1] */
    vertical-align: middle; /* [2] */
    font: inherit; /* [3] */
    text-align: center; /* [4] */
    margin:  0; /* [4] */
    cursor: pointer; /* [5] */
    overflow: visible; /* [6] */
    padding: $btn-padding - $btn-border-width  round($btn-padding * 2) - $btn-border-width; /* [7] */
    background-color: $btn-background;

    @if($btn-border-width != 0) {
        border: $btn-border-width $btn-border-style $btn-border-color;
    } @else {
        border: none; /* [4] */
    }

    @if($btn-radius != 0) {
        border-radius: $btn-radius;
    }

    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none; /* [4] */
        color: $btn-color;
    }

}

/**
 * Fix a Firefox bug whereby `input type="submit"` gains 2px extra padding.
 */
.o-btn::-moz-focus-inner,
%o-btn::-moz-focus-inner {
    border:  0;
    padding: 0;
}


@if ($enable-btn--small == true) {

    /**
     * Small buttons.
     */

    .o-btn--small,
    %o-btn--small {
        padding: $btn-padding--small - $btn-border-width  round($btn-padding--small * 2) - $btn-border-width; /* [7] */
    }

}

@if ($enable-btn--large == true) {

    /**
     * Large buttons.
     */

    .o-btn--large,
    %o-btn--large {
        padding: $btn-padding--large - $btn-border-width  round($btn-padding--large * 2) - $btn-border-width; /* [7] */
    }

}

@if ($enable-btn--full == true) {

    /**
     * Full-width buttons.
     */

    .o-btn--full,
    %o-btn--full {
        width: 100%;

        @if $global-border-box == false {
            /**
             * Remove paddings so that widths and paddings don’t conflict.
             */
            padding-right: 0;
            padding-left:  0;
        }

    }

}

@if ($enable-btn--pill == true) {

    /**
     * Round-ended buttons.
     *
     * 1. Overly-large value to ensure the radius rounds the whole end of the
     *    button.
     */

    .o-btn--pill,
    %o-btn--pill {
        border-radius: 100px; /* [1] */
    }

}
