/*
    Elements: page
    ---
    Default markup for page level elements
*/
html {
    line-height: 1.2;
    font-size: ($base-font-size / 16px) * .95em;

    @include media-query(tablet) {
        //font-size: ($base-font-size / 16px) * .9em;
    }

    @include media-query(lap-and-up) {
        font-size: ($base-font-size / 16px) * 1em;
        line-height: $base-line-height / $base-font-size;
    }
}

hr {
    border: none;
    border-top: 1px solid #E0E0E0;
}
