/*
    Objects: Box
    ---
    Default markup for boxed off content
*/

/**
 * The box object simply boxes off content.
 */

// Predefine the variables below in order to alter and enable specific features.
$box-padding:         $base-spacing-unit !default;

$enable-box--flush:   false !default;

/**
 * 1. So we can apply the `.box` class to naturally-inline elements.
 */
.o-box,
%o-box {
    @extend %u-clearfix;
    display: block; /* [1] */
    padding: $box-padding;

    > :last-child {
        margin-bottom: 0;
    }

}


@if ($enable-box--flush == true) {

    /**
     * Flush (i.e. no padding) box.
     */

    .o-box--flush,
    %o-box--flush {
        padding: 0;
    }

}
