/*
    Components: Form elements
    ---
*/

.c-form-row {
    margin-bottom: 1em;

    label {
        font-weight: bold;
    }

    input[type="text"], input[type="email"], input[type="tel"], input[type="password"], select {
        width: 100%;
        padding: .5em;
    }

    select {
        height: calc(2rem + 2px);
    }

    textarea {
        width: 100%;
        padding: 2px .5em;
    }

    .flexbox &__group {
        display: flex;

        input:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        input:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none;
        }
    }
}

.c-form {
    &__inline-error-message {
        padding-top: .25em;
        padding-left: .5em;
        padding-right: .5em;
        color: #f5120d;
    }
}

// vanuit een andere file afkomstig (origineel "components.forms" ??): //

input, textarea, fieldset, select {
    border: 1px solid $grey-04;
    background-color: $white;
}

.message {
    margin: 1em 0;
    padding: 1em 1.5em;
    line-height: 1.3;
}

.error {
    box-shadow: 0 0 0 1px #dbb1b1 inset,0 1px 2px 0 rgba(0,0,0,.05);
    color: #a94442;
    background-color: #fff0f0;
}

.success {
    box-shadow: 0 0 0 1px #b7caa7 inset,0 1px 2px 0 rgba(0,0,0,.05);
    color: #356e36;
    background-color: #eeffe7;
}

.form-legend {
    color: $grey-02;
    font-style: italic;
    margin-top: -1em; // undo c-form-row margin-bottom
}
.required-field {
    color: #444;
}
