.masthead {
    background-image: url("/assets/images/backdrop-pattern.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    transform: translateY(-30px);
    width: 340px;
    padding: 1em;

    @include media-query("lap-and-up") {
        transform: translateY(-60px);
    }
}

.home {
    .masthead {
        height: 70vh;
    }

    .logo {
        transform: translateY(-30px);
        width: 600px;
    }
}

.intro {
    transform: translateY(-2em);
    margin-left: auto;
    margin-right: auto;
    max-width: 40em;
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;

    @include media-query("lap-and-up") {
        font-size: 1.4em;
    }
}

.content {
    max-width: 50rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8rem;
}

.formsection {
    margin-bottom: 3em;

    &__header {
        text-align: center;
        border-bottom: 1px solid #DCDBE7;
        margin-bottom: 1.5em;
        padding-bottom: .15rem;
    }

    .c-form-row {
        @include media-query("lap-and-up") {
            margin-right: 1em;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .form__row {
        @include media-query("lap-and-up") {
            display: flex;
        }
    }
}

.language-nav {
    width: 90px;
    height: 45px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border-left: 1px solid #DCDBE7;

    &__container {
        text-align: center;
        transform: translateY(-3.5em);

        @include media-query("lap-and-up") {
            transform: translateY(-5em);
        }
    }

    &__link {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 1px solid #DCDBE7;
        border-left: none;
        color: $alpha-text-color;
        font-size: .8em;
        text-transform: uppercase;

        &--active {
            background-color: $alpha-ui-color !important;
            color: #fff !important;
        }

        &:hover {
            background-color: #f5f5f5;
            color: $alpha-ui-color;
        }
    }
}

.site-footer {
    border-top: 1px solid #DCDBE7;
    font-size: .8em;
    padding-top: 1em;
    margin-bottom: 2rem;
}