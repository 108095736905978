/*
    Objects: List-block
    ---
    Default markup for boxed off list-block
*/

/**
 * The list-block object creates blocky list items out of a `ul` or `ol`.
 */

// Predefine the variables below in order to alter and enable specific features.
$list-block-padding:          $base-spacing-unit !default;

.o-list-block,
%o-list-block {
    margin:  0;
    padding: 0;
    list-style: none;

    > li {
        @extend %o-list-block__item;
    }

}

    .o-list-block__item,
    %o-list-block__item {
        padding: $list-block-padding;
    }
