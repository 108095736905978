/*
    Elements: Tables
    ---
    Default markup & helpers for tables
*/

/* Enable BP features */
$enable-table--cells: true;

// Apply basic styling
.table {
    @include media-query(lap-and-up) {
        font-size: .9em;
    }

    th {
        text-align: left;
        padding: .1em .5em;
        border-bottom: 3px solid $grey-02;
    }

    td {
        padding: .4em;
        border: 1px solid $grey-05;
    }

    &--responsive {
        overflow-x: scroll;
    }
}
.table--rotated {
    th {
        border: 1px solid $grey-05;
        border-right: 3px solid $grey-02;
    }
}
