//
//  Settings: global
//  ---
//  Set global variables and turn global settings on/off
//

// High-level base settings.
$base-font-size:          16px !default;
$base-line-height:        24px !default;
$base-text-color:         #333 !default;
$base-background-color:   #fff !default;

$base-spacing-unit: $base-line-height;

$debug-mode: false; // toggles the debug mode
