/*
    Objects: Grid
    ---
    Default markup for grid
*/

/**
 * The grid system uses `box-sizing: border-box;` and
 * `display: inline-block;` to create an extremely powerful, flexible
 * alternative to the traditional grid system. Combine the grid items with
 * the widths found in `trumps.widths`.
 */

/*
 * Example usage:
 *
 * <div class="grid">
 *     <div class="grid__item unit-1-2"></div>
 *     <div class="grid__item unit-1-2"></div>
 *     <div class="grid__item unit-1-3"></div>
 *     <div class="grid__item unit-1-3"></div>
 * </div>
 *
 * <div class="grid grid--center">
 *     <div class="grid__item unit-1-3"></div>
 *     <div class="grid__item unit-1-3"></div>
 * </div>
 */

// Predefine the variables below in order to alter and enable specific features.
$grid-gutter:             $base-spacing-unit !default;
$grid-fontsize:           $base-font-size !default;

$enable-grid--flush:    false !default;
$enable-grid--rev:      false !default;
$enable-grid--middle:   false !default;
$enable-grid--bottom:   false !default;
$enable-grid--right:    false !default;
$enable-grid--center:   false !default;
$enable-grid--auto:     false !default;


// Here we set a variable assuming that `box-sizing: border-box;` is not set
// globally. If it has been previously been defined, the following variable will
// be overriden and will be set to `true`.
$global-border-box: false !default;


/**
 * Core grid component
 *
 * DO NOT apply dimension or offset utilities to the `grid` element. All cell
 * widths and offsets should be applied to child grid items.
 */

/* Grid container
   ========================================================================== */

/**
 * All content must be contained within child `grid__item` elements.
 *
 * 1. Account for browser defaults of elements that might be the root node of the component.
 * 2. Remove inter-cell whitespace that appears between `inline-block` child elements.
 * 3. Ensure consistent default alignment.
 * 4. Default gutters.
 */

.o-grid,
%o-grid {
    display: block; /* [1] */
    font-size: 0; /* [2] */
    list-style: none;
    margin:  0; /* [1] */
    padding: 0; /* [1] */
    text-align: left; /* [3] */
    margin-left: -$grid-gutter; /* [4] */
}

     /* Grid item
       ========================================================================== */

    /**
     /**
     * No explicit width by default. Rely on combining `grid__item` with a dimension
     * utility or a component class that extends 'grid'
     *
     * 1. Fundamentals of the non-float grid layout.
     * 2. Reset font size change made in `Grid`.
     * 3. Space columns apart.
     * 4. Align columns to the tops of each other.
     * 5. Full-width unless told to behave otherwise.
     * 6. Keeps content correctly aligned with the grid direction.
     * 7. Required to combine fluid widths and fixed gutters.
     */
    .o-grid__item,
    %o-grid__item {
        display: inline-block; /* [1] */
        font-size: $grid-fontsize; /* [2] */
        padding-left: $grid-gutter; /* [3] */
        vertical-align: top; /* [4] */
        width: 100%; /* [5] */
        text-align: left; /* [6] */

        @if $global-border-box == false {
            box-sizing: border-box; /* [7] */
        }
    }

    /**
     * Modifier: horizontally center one unit
     * Set a specific unit to be horizontally centered. Doesn't affect
     * any other units. Can still contain a child `Grid` object.
     */
    .o-grid__item--center,
    %o-grid__item--center {
        display: block;
        margin: 0 auto;
    }

@if ($enable-grid--flush == true) {
    /**
     * Layouts with no gutters.
     */

    .o-grid--flush,
    %o-grid--flush {
        margin-left: 0;

        > .o-grid__item,
        > %o-grid__item {
            padding-left: 0;
        }

    }

}

@if ($enable-grid--rev == true) {
    /**
     * Reversed rendered order of grid items, e.g. items 1, 2, 3, 4 in your
     * markup will display in order 4, 3, 2, 1 on your page.
     */

    .o-grid--rev,
    %o-grid--rev {
        direction: rtl;
        text-align: left;

        > .o-grid__item,
        > %o-grid__item {
            direction: ltr;
            text-align: left;
        }

    }

}

@if ($enable-grid--middle == true) {
    /**
     * Align grid items to the vertical centers of each other.
     */

    .o-grid--middle,
    %o-grid--middle {

        > .o-grid__item,
        > %o-grid__item {
            vertical-align: middle;
        }

    }

}

@if ($enable-grid--bottom == true) {
    /**
     * Align grid items to the vertical bottoms of each other.
     */

    .o-grid--bottom,
    %o-grid--bottom {

        > .o-grid__item,
        > %o-grid__item {
            vertical-align: bottom;
        }

    }

}

@if ($enable-grid--right == true) {
    /**
     * Make the grid items fill up from the right hand side.
     */

    .o-grid--right,
    %o-grid--right {
        text-align: right;

        > .o-grid__item,
        > %o-grid__item {
            text-align: left;
        }

    }

}

@if ($enable-grid--center == true) {
    /**
     * Make the grid items fill up from the center outward.
     */

    .o-grid--center,
    %o-grid--center {
        text-align: center;

        > .o-grid__item,
        > %o-grid__item {
            text-align: left;
        }

    }

}

@if ($enable-grid--auto == true) {
    /**
     * Cause grid items to take up a non-explicit amount of width.
     */
    .o-grid--auto,
    %o-grid--auto {

        > .o-grid__item,
        > %o-grid__item {
            width: auto;
        }

    }

}
