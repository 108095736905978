/*
 *  SCSS Boilerplate
 *  ---
 *  SCSS Boilerplate is a starter kit based on the [ITCSS](http://itcss.io/)
 *  architecture and a series of principles. It creates a structure
 *  to easily set-up and build bespoke web projects.
 *  ---
 *  The settings and tools layer don't compile any code.
 *  Therefore they get the number 0 and are put in single line comments.
 *  ---
 *  A lot loans heavily on [inuitcss](https://github.com/inuitcss) and
 *  [SUIT CSS](https://github.com/suitcss) components.
 *
 */

    // 1. Settings
    @import "custom/1-settings/settings.bp";
    @import "custom/1-settings/settings.colors";
    @import "custom/1-settings/settings.typography";
    @import "custom/1-settings/settings.breakpoints";
    /* ----------------------------------------------------------------*/
    @import "bp/1-settings/settings.global";
    @import "bp/1-settings/settings.typography";
    @import "bp/1-settings/settings.ms";
    @import "bp/1-settings/settings.colors";
    @import "bp/1-settings/settings.ui";
    @import "bp/1-settings/settings.breakpoints";
    /* ----------------------------------------------------------------*/

    // 2. Tools

    @import "bp/2-tools/tools.widths";
    @import "bp/2-tools/tools.media-query";
    @import "bp/2-tools/tools.tw-media-query";
    @import "bp/2-tools/tools.mixins";
    @import "bp/2-tools/tools.vertical-center";

    /* 3. Generic */

    @import "bp/3-generic/generic.normalize";
    @import "bp/3-generic/generic.reset";
    @import "bp/3-generic/generic.border-box";
    @import "bp/3-generic/generic.vertical-rhythm";
    // Elements
    @import "bp/3-generic/generic.page";
    @import "bp/3-generic/generic.headings";
    @import "bp/3-generic/generic.paragraphs";
    @import "bp/3-generic/generic.lists";
    @import "bp/3-generic/generic.images";
    @import "bp/3-generic/generic.tables";
    @import "bp/3-generic/generic.forms";
    /* ----------------------------------------------------------------*/
    @import "custom/3-generic/generic.page";
    @import "custom/3-generic/generic.typography";
    @import "custom/3-generic/generic.links";
    @import "custom/3-generic/generic.tables";
    @import "custom/3-generic/generic.blockquotes";

    /* 4. Objects */

    @import "bp/4-objects/objects.buttons";
    // 4.1 Media (Abstract image + text combo)
    @import "bp/4-objects/objects.media";
    @import "bp/4-objects/objects.media-flag";
    @import "bp/4-objects/objects.media-block";
    // 4.2 UI
    //@import "bp/4-objects/objects.tabs";
    @import "bp/4-objects/objects.box";
    // 4.3 Lists
    @import "bp/4-objects/objects.list-bare";
    @import "bp/4-objects/objects.list-block";
    @import "bp/4-objects/objects.list-ui";
    @import "bp/4-objects/objects.list-inline";
    // 4.4 Grid
    @import "bp/4-objects/objects.grid";
    /* ----------------------------------------------------------------*/
    @import "custom/4-objects/objects.box";
    @import "custom/4-objects/objects.fgrids";
    @import "custom/4-objects/objects.modal";

    /* 5. Components */
    @import "custom/5-components/components.buttons";
    @import "custom/5-components/components.forms";
    @import "custom/5-components/components.page";

    /* 6. Plugins */

    @import "bp/6-plugins/plugins.gmaps";
    @import "bp/6-plugins/plugins.uniform";
    /* ----------------------------------------------------------------*/
    @import "custom/6-plugins/plugins.kirby";
    @import "custom/6-plugins/plugins.uniform";
    //@import "../../../bower_components/owl.carousel/dist/assets/owl.carousel.min.scss";
    //@import "custom/6-plugins/owl.theme.default.min.scss";

    /* 7. Trumps */

    @import "bp/7-trumps/trumps.clearfix";
    @import "bp/7-trumps/trumps.display";
    @import "bp/7-trumps/trumps.layout";
    @import "bp/7-trumps/trumps.widths";
    @import "bp/7-trumps/trumps.link";
    @import "bp/7-trumps/trumps.position";
    @import "bp/7-trumps/trumps.text";
    @import "bp/7-trumps/trumps.print";
    @import "bp/7-trumps/trumps.debug";
    /* ----------------------------------------------------------------*/
    @import "custom/7-trumps/trumps.shame";
    @import "custom/7-trumps/trumps.nojs";
