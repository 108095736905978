/*
    Objects: media-block
    ---
    Default markup for media-block
*/

/**
 * The block object simply stacks an image on top of some text-like content.
 */

// Predefine the variables below in order to alter and enable specific features.
$block-gutter:            $base-spacing-unit !default;

$enable-block--flush:     false !default;
$enable-block--center:    false !default;
$enable-block--right:     false !default;


/**
 * Stacked image-with-text object. A simple abstraction to cover a very commonly
 * occurring design pattern.
 */
.o-block,
%o-block {
    display: block;
}


    /**
     * 1. Eliminate whitespace around images.
     */
    .o-block__img,
    %o-block__img {
        vertical-align: middle; /* [1] */
        margin-bottom: $block-gutter;

        @if ($enable-block--flush == true) {

            /**
             * No space between the image and the text content.
             */

            .o-block--flush > &,
            %o-block--flush > & {
                margin-bottom: 0;
            }

        }

    }


    /**
     * Text-content.
     */
    .o-block__body,
    %o-block__body {
        display: block;
    }

@if ($enable-block--right == true) {

    /**
     * Right-aligned blocks.
     */

    .o-block--right,
    %o-block--right {
        text-align: right;
    }

}

@if ($enable-block--center == true) {

    /**
     * Center-aligned blocks.
     */

    .o-block--center,
    %o-block--center {
        text-align: center;
    }

}
