//
//  Settings: UI
//  ---
//  Set your basic UI preferences like border-radius, border-width, etc.
//

$radius                     : 0;
$border-width               : 1px;
$border-style               : solid;
$depth                      : 0%; // Range: 0-100%
$contrast                   : 91%; // Range: 0-100%
$shadow                     : 21%; // Range: 0-100%
$highlight                  : 0%; // Range: 0-100%
