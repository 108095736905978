/*------------------------------------*\
    # kirbytext enhancements
\*------------------------------------*/

.kt {
    figure.align-right {
        float: right;
        margin: 0 0 1.5em 1.5em;
        @include media-query(palm) {
            float: none;
            margin-left: 0;
        }
    }
    figure.align-left {
        float: left;
        margin: 0 1.5em 1.5em 0;
        @include media-query(palm) {
            float: none;
            margin-right: 0;
        }
    }
    figure.align-center {
        text-align: center;
    }
    figure {
        &.video {
            position: relative;
            height: 0;
            padding-bottom: 56.25%; //16:9
            overflow: hidden;

            iframe, object, embed, video {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
            }

        }
    }
    p, ul, ol {
        max-width: 55rem;
    }
    ul, ol {
        margin-left: 1.3em;
    }
    p a, li a {
        text-decoration: underline;
    }
    .owl-carousel {
        margin-bottom: 1.4em;
    }
    h2, h3, h4, h5 {
        clear: both;
    }
}
