/*
    Elements: Links
    ---
    Default markup for links
*/

a {
    text-decoration: none;
    color: $alpha-link-color;

    p &:hover,
    p &:active,
    p &:focus {
        text-decoration: underline;
    }

    img { border:none; }
}
