/*
    Objects: fgrid
    ---
    Flexbox grid
    <!> Heavily reliant on modernizr flexbox class!
    @todo Check for @supports support instead of flexbox?
    @todo Check responsive width modifiers
*/

$fgrid-widths-namespace: "fgrid";
$fgrids-use-fractions: true;

.flexbox {

    .fgrid {
      display: flex;
      //flex-wrap: wrap;
      flex-flow: row wrap;
    }

    .fgrid__cell {
      flex: 0 0 auto;
    }

    /* With gutters */
    .fgrid--gutters {
      margin: -1em 0 0 -1em;
    }
    .fgrid--gutters > .fgrid__cell {
      padding: 1em 0 0 1em;
    }

    /* Alignment per row */
    .fgrid--top {
      align-items: flex-start;
    }
    .fgrid--bottom {
      align-items: flex-end;
    }
    .fgrid--center {
      align-items: center;
    }

    /* Alignment per cell */
    .fgrid__cell--top {
      align-self: flex-start;
    }
    .fgrid__cell--bottom {
      align-self: flex-end;
    }
    .fgrid__cell--center {
      align-self: center;
    }

    /* Responsive modifiers */
    .fgrid--fit > .fgrid__cell {
      flex: 1;
    }

    /* default widths */
    @if $fgrids-use-fractions == true {

        /**
         * Whole.
         */
        [class~="#{$fgrid-widths-namespace}-1/1"]     { width: 100%; }

        /**
         * Halves.
         */
        [class~="#{$fgrid-widths-namespace}-1/2"],
        [class~="#{$fgrid-widths-namespace}-2/4"],
        [class~="#{$fgrid-widths-namespace}-3/6"],
        [class~="#{$fgrid-widths-namespace}-4/8"],
        [class~="#{$fgrid-widths-namespace}-5/10"],
        [class~="#{$fgrid-widths-namespace}-6/12"]     { width: 50%; }

        /**
         * Thirds.
         */
        [class~="#{$fgrid-widths-namespace}-1/3"],
        [class~="#{$fgrid-widths-namespace}-2/6"],
        [class~="#{$fgrid-widths-namespace}-3/9"],
        [class~="#{$fgrid-widths-namespace}-4/12"]     { width: 33.3333%; }
        [class~="#{$fgrid-widths-namespace}-2/3"],
        [class~="#{$fgrid-widths-namespace}-4/6"],
        [class~="#{$fgrid-widths-namespace}-6/9"],
        [class~="#{$fgrid-widths-namespace}-8/12"]     { width: 66.6666666% ; }

        /**
         * Quarters.
         */
        [class~="#{$fgrid-widths-namespace}-1/4"],
        [class~="#{$fgrid-widths-namespace}-2/8"],
        [class~="#{$fgrid-widths-namespace}-3/12"]     { width: 25%; }
        [class~="#{$fgrid-widths-namespace}-3/4"],
        [class~="#{$fgrid-widths-namespace}-6/8"],
        [class~="#{$fgrid-widths-namespace}-9/12"]     { width: 75%; }

        /**
         * Fifths.
         */
        [class~="#{$fgrid-widths-namespace}-1/5"],
        [class~="#{$fgrid-widths-namespace}-2/10"]     { width: 20%; }
        [class~="#{$fgrid-widths-namespace}-2/5"],
        [class~="#{$fgrid-widths-namespace}-4/10"]     { width: 40%; }
        [class~="#{$fgrid-widths-namespace}-3/5"],
        [class~="#{$fgrid-widths-namespace}-6/10"]     { width: 60%; }
        [class~="#{$fgrid-widths-namespace}-4/5"],
        [class~="#{$fgrid-widths-namespace}-8/10"]     { width: 80%; }

        /**
         * Sixths.
         */
        [class~="#{$fgrid-widths-namespace}-1/6"],
        [class~="#{$fgrid-widths-namespace}-2/12"]     { width: 16.6666666%; }
        [class~="#{$fgrid-widths-namespace}-5/6"],
        [class~="#{$fgrid-widths-namespace}-10/12"]    { width: 83.3333333%; }
        /**
         * Eighths.
         */
        [class~="#{$fgrid-widths-namespace}-1/8"]      { width: 12.5%; }
        [class~="#{$fgrid-widths-namespace}-3/8"]      { width: 37.5%; }
        [class~="#{$fgrid-widths-namespace}-5/8"]      { width: 62.5%; }
        [class~="#{$fgrid-widths-namespace}-7/8"]      { width: 87.5%; }

        /**
         * Ninths.
         */
        [class~="#{$fgrid-widths-namespace}-1/9"]      { width: 11.1111111%; }
        [class~="#{$fgrid-widths-namespace}-2/9"]      { width: 22.2222222%; }
        [class~="#{$fgrid-widths-namespace}-4/9"]      { width: 44.4444444%; }
        [class~="#{$fgrid-widths-namespace}-5/9"]      { width: 55.5555555%; }
        [class~="#{$fgrid-widths-namespace}-7/9"]      { width: 77.7777777%; }
        [class~="#{$fgrid-widths-namespace}-8/9"]      { width: 88.8888888%; }

        /**
         * Tenths.
         */
        [class~="#{$fgrid-widths-namespace}-1/10"]     { width: 10%; }
        [class~="#{$fgrid-widths-namespace}-3/10"]     { width: 30%; }
        [class~="#{$fgrid-widths-namespace}-7/10"]     { width: 70%; }
        [class~="#{$fgrid-widths-namespace}-9/10"]     { width: 90%; }

        /**
         * Twelfths.
         */
        [class~="#{$fgrid-widths-namespace}-1/12"]     { width: 8.3333333%; }
        [class~="#{$fgrid-widths-namespace}-5/12"]     { width: 41.6666666%; }
        [class~="#{$fgrid-widths-namespace}-7/12"]     { width: 58.3333333%; }
        [class~="#{$fgrid-widths-namespace}-11/12"]    { width: 91.6666666%; }
    } // Close fractions @if.


    /* responsive widths */
    @if $fgrids-use-fractions == true {

        @each $breakpoint in $breakpoints {

            $alias:     nth($breakpoint, 1);
            $condition: nth($breakpoint, 2);

            // This isn’t ideal, but we definitely don’t want to generate widths
            // for retina devices. Exclude retina media-qeuries manually.
            @if ($alias != "retina") {

                @include media-query($alias) {

                    /**
                     * Whole.
                     */
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-1/1"]     { width: 100%; }

                    /**
                     * Halves.
                     */
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-1/2"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-2/4"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-3/6"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-4/8"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-5/10"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-6/12"]     { width: 50%; }

                    /**
                     * Thirds.
                     */
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-1/3"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-2/6"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-3/9"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-4/12"]     { width: 33.3333%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-2/3"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-4/6"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-6/9"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-8/12"]     { width: 66.6666666% ; }

                    /**
                     * Quarters.
                     */
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-1/4"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-2/8"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-3/12"]     { width: 25%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-3/4"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-6/8"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-9/12"]     { width: 75%; }

                    /**
                     * Fifths.
                     */
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-1/5"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-2/10"]     { width: 20%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-2/5"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-4/10"]     { width: 40%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-3/5"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-6/10"]     { width: 60%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-4/5"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-8/10"]     { width: 80%; }

                    /**
                     * Sixths.
                     */
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-1/6"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-2/12"]     { width: 16.6666666%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-5/6"],
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-10/12"]    { width: 83.3333333%; }
                    /**
                     * Eighths.
                     */
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-1/8"]      { width: 12.5%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-3/8"]      { width: 37.5%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-5/8"]      { width: 62.5%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-7/8"]      { width: 87.5%; }

                    /**
                     * Ninths.
                     */
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-1/9"]      { width: 11.1111111%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-2/9"]      { width: 22.2222222%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-4/9"]      { width: 44.4444444%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-5/9"]      { width: 55.5555555%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-7/9"]      { width: 77.7777777%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-8/9"]      { width: 88.8888888%; }

                    /**
                     * Tenths.
                     */
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-1/10"]     { width: 10%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-3/10"]     { width: 30%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-7/10"]     { width: 70%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-9/10"]     { width: 90%; }

                    /**
                     * Twelfths.
                     */
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-1/12"]     { width: 8.3333333%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-5/12"]     { width: 41.6666666%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-7/12"]     { width: 58.3333333%; }
                    [class~="#{$fgrid-widths-namespace}-#{$alias}-11/12"]    { width: 91.6666666%; }

                } // Close media query.

            } // Close retina check.

        } // Close breakpoints loop.

    } // Close fractions @if.
}
