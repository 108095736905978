/*
    Objects: List-bare
    ---
    Default markup for boxed off list-bare
*/

/**
 * The list-bare object simply removes any indents and bullet points from lists.
 */

// Predefine the variables below in order to alter and enable specific features.

.o-list-bare,
%o-list-bare {
    margin:  0;
    padding: 0;
    list-style: none;
}
