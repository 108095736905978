/*
    Elements: Tables
    ---
    Default markup for tables elements
*/
// Predefine the variables below in order to alter and enable specific features.
$table-border-width:      1px !default;
$table-border-style:      solid !default;
$table-border-color:      #ccc !default;

$enable-table--fixed:     false !default;
$enable-table--cells:     false !default;
$enable-table--rows:      false !default;
$enable-table--columns:   false !default;

.table,
%table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

td, th {
    padding: .25em 1em .25em 0;
}

@if ($enable-table--fixed == true) {

    /**
     * Force tables into having equal-width columns.
     */
    .table--fixed,
    %table--fixed {
        table-layout: fixed;
    }

}

@if ($enable-table--cells == true) {

    /**
     * Add borders around a `table`.
     */
    .table--cells,
    %table--cells {

        th,
        td {
            border: $table-border-width $table-border-style $table-border-color;
        }

    }

}

@if ($enable-table--rows == true) {

    /**
     * Add borders only to `table`’s rows.
     */
    .table--rows,
    %table--rows {

        &,
        th,
        td {
            border: 0 $table-border-style $table-border-color;
        }

    }

    .table--rows,
    %table--rows {
        border-top-width: $table-border-width;

        th,
        td {
            border-bottom-width: $table-border-width;
        }
    }

}

@if ($enable-table--columns == true) {

    /**
     * Add borders only to `table`’s columns.
     */
    .table--columns,
    %table--columns {

        &,
        th,
        td {
            border: 0 $table-border-style $table-border-color;
        }
    }

    .table--columns,
    %table--columns {
        border-left-width: $table-border-width;

        th,
        td {
            border-right-width: $table-border-width;
        }
    }

}
