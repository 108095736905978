/*
    Objects: media
    ---
    Default markup for media
*/
/*
    http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/
    ---
    The media object is an image to the left, with descriptive content to the right.
    Use a grid class to determine the size of the image. The content will take the remaining space.
    ---
    Example usage:
    <article class="media">
      <div class="media__img grid-4">
        <img src="http://placekitten.com/200/300" alt="placekitten" />
      </div>
      <div class="media__body">
        The media object is an image to the left, with descriptive content to the right.
      </div>
    </article>
*/

// Predefine the variables below in order to alter and enable specific features.
$media-gutter:                $base-spacing-unit !default;
$enable-media--rev:           false !default;
$enable-media--flush:         false !default;
// $enable-media--responsive:    false !default;
// $media-collapse-at:           720px !default;

.o-media,
%o-media {
    @extend %u-clearfix;
    display: block;
}

.o-media__img,
%o-media__img {
    float: left;
    margin-right: $media-gutter;

    img {
        display: block;
    }
}

.o-media__body,
%o-media__body {
    overflow: hidden;
    display: block;

    &,
    > :last-child {
        margin-bottom: 0;
    }
}

@if ($enable-media--rev == true) {

    /**
     * Reversed image location (right instead of left). Extends `.media`.
     */

    .o-media--rev,
    %o-media--rev {

        > .o-media__img,
        > %o-media__img {
            float: right;
            margin-right: 0;
            margin-left: $media-gutter;
        }

    }

}

@if ($enable-media--flush == true) {
    /**
     * Flush media objects have no space between the image- and text-content.
     */

    .o-media--flush,
    %o-media--flush {

        > .o-media__img,
        > %o-media__img {
            margin-right: 0;
            margin-left:  0;
        }

    }
}
