//
//  Tools: media-query mixin
//  ---
// A simple mixin to quickly generate whole media queries from the aliases and
// conditions defined in `_settings.breakpoints.scss`.
//
// Usage:
//
// .foo {
//     color: green;
//
//     @include media-query(palm) {
//         color: red;
//     }
//
// }

// Here we set a variable assuming that responsive settings are not set
// globally. If they have been previously been defined, the following variable
// will be overriden and will be set to `true`.
$responsive-settings: false !default;

@if ($responsive-settings == false) {
    @warn "Oops! Have you included settings.breakpoints?"
}

@mixin media-query($mq) {
    $breakpoint-found: false;
    // Loop through the list of breakpoints we’ve provided in our settings file.
    @each $breakpoint in $breakpoints {

        // Grab the alias and the condition from their respective locations in
        // the list.
        $alias:     nth($breakpoint, 1);
        $condition: nth($breakpoint, 2);

        // If the media query we’ve specified has an alias and a condition...
        @if $mq == $alias and $condition {

            // ...tell the mixin that we’ve found it...
            $breakpoint-found: true;

            // ...and spit it out here.
            @media #{$condition} {
                @content;
            }
        }
    }

    // If the user specifies a non-exitent alias, send them a warning.
    @if $breakpoint-found == false {
        @warn "Oops! Breakpoint ‘#{$mq}’ does not exist."
    }

}




// Expose our media-queries to JS via CSS method
// Eliminate the need for a JS solution for detecting media queries (as they are set and managed in CSS)
// Via: http://zerosixthree.se/detecting-media-queries-with-javascript/

@mixin expose-media-query() {
    // Loop through the list of breakpoints we’ve provided in our settings file.
    @each $breakpoint in $breakpoints {

        // Grab the alias and the condition from their respective locations in
        // the list.
        $alias:     nth($breakpoint, 1);

        body:after {
            @include media-query($alias) {
                content: $alias;
                display: none;
            }
        }
    }
}

@include expose-media-query();
