/*
    Trumps: widths
    ---
    Dependency: tools.widths

 * A series of width helper classes that you can use to size things like grid
 * systems. Classes can take a fraction-like format (e.g. `.u-2/3`) or a spoken-
 * word format (e.g. `.u-2-of-3`). Use these in your markup:
 *
 * <div class="u-7/12">
 */

 // Predefine the variables below in order to alter and enable specific features.
$enable-push-classes:           false !default;
$widths-columns: (              // By default we will create wholes, halves, thirds, quarters, and fifths.  Predefine this Map to override.
    1,
    2,
    3,
    4,
    5,
) !default;

@include widths($widths-columns);

/* Responsive widths */
@if ($responsive-settings == true) {
    // Loop over our breakpoints defined in _settings.breakpoints.scss
    @each $breakpoint in $breakpoints {

      // Get the name of the breakpoint.
      $alias: nth($breakpoint, 1);

      @include media-query($alias) {

        // Loop through each of our column sizes and generate its responsive width classes.
        @each $widths-column in $widths-columns {
          @include widths($widths-column, -#{$alias});
        }

      }

    }
}

/* Add push classes */
@if ($enable-push-classes == true) {
    // Add default push classes
    @include push($widths-columns);

    @if ($responsive-settings == true) {
        // Loop over our breakpoints defined in _settings.breakpoints.scss
        @each $breakpoint in $breakpoints {

          // Get the name of the breakpoint.
          $alias: nth($breakpoint, 1);

          @include media-query($alias) {

            // Loop through each of our column sizes and generate its responsive width classes.
            @each $widths-column in $widths-columns {
              @include push($widths-column, -#{$alias});
            }
          }
        }
    }
}
