/*
    Plugins: Uniform
    ---

    Style voor de Uniform Kirby plugin
*/

// Hide honeypot field for anti-spam
.uniform__potty {
	position: absolute;
	top: -9999px;
	left: -9999px;
}
