/*
    Objects: modal
    ---

    Based on: http://tympanus.net/codrops/?p=15313
*/

/*
Styles for the html/body for special modal where we want 3d effects
Note that we need a container wrapping all content on the page for the
perspective effects (not including the modals and the overlay).
*/
.md-perspective,
.md-perspective body {
	height: 100%;
	overflow: hidden;
}

.md-perspective body  {
	background: #222;
	-webkit-perspective: 600px;
	-moz-perspective: 600px;
	perspective: 600px;
}

.container {
	background: $beta-ui-color-dark;
	min-height: 100%;
}

.md-modal {
	position: absolute;
	//top: 50%;
	//left: 50%;
    top: 10vh;
    left: 50%;
	width: 70%;
	max-width: 900px;
	min-width: 320px;
	height: auto;
	z-index: 2000;
	visibility: hidden;
	backface-visibility: hidden;
    transform: translateX(-50%);
	//transform: translateX(-50%) translateY(-50%);
}

.md-show {
	visibility: visible;
}

.md-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	visibility: hidden;
	top: 0;
	left: 0;
	z-index: 1000;
	opacity: 0;
    background: rgba(22,22,22,0.8);
	transition: all 0.3s;
}

.md-show ~ .md-overlay {
	opacity: 1;
	visibility: visible;
}

/* Content styles */
.md-content {
	color: #404040;
	background: #fff;
	position: relative;
	border-radius: 3px;
	margin: 0 auto 4em;
    -webkit-font-smoothing: antialiased;

    h3 {
        color: #fff;
        background-color: $alpha-ui-color;
    	margin: 0;
        margin-bottom: 1em;
    	padding: 0.4em;
    	text-align: center;
    	font-size: 2.4em;
    	font-weight: 300;
    	opacity: 0.8;
    	border-radius: 3px 3px 0 0;
    }

    a {
        color: #fff;
    }

    > div {
    	padding: 15px 40px 30px;
    	margin: 0;
    	font-weight: 300;
    	//font-size: 1.15em;
    }

    > div p {
    	margin: 0;
    	padding: 10px 0;
    }

    > div ul {
    	margin: 0;
    	padding: 0 0 30px 20px;
    }

    > div ul li {
    	padding: 5px 0;
    }
}

/* Individual modal styles with animations/transitions */

/* Effect 3: Slide from the bottom */
.md-effect-slide-bottom .md-content {
	transform: translateY(0);
	opacity: 0;
	transition: all 0.3s;
}

.md-show.md-effect-slide-bottom .md-content {
	//transform: translateY(20%);
	opacity: 1;
}
