/*------------------------------------*\
    #TYPE
\*------------------------------------*/

/**
 * Helper function
 */
$rem-base: $base-font-size;

// STRIP UNIT
// It strips the unit of measure and returns it
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// CONVERT TO REM
@function convert-to-rem($value, $base-value: $rem-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);

  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

/**
 * Alias
 */
$body-font-family: $alpha-font-family;
$font-weight-normal:300;
$font-weight-bold: bold;

/**
 * Set font-family for site
 */

// We use these to control header font styles
$header-font-family: "sourcesans", Arial;
$header-font-weight: 300 !default;
$header-font-style: normal !default;
$header-font-color: $beta-text-color !default;
$header-line-height: 1.2 !default;
$header-top-margin: .2rem !default;
$header-bottom-margin: .5rem !default;
$header-text-rendering: optimizeLegibility !default;

// We use these to control header font sizes
$h1-font-size: rem-calc(44) !default;
$h2-font-size: rem-calc(23) !default;
$h3-font-size: rem-calc(20) !default;
$h4-font-size: rem-calc(18) !default;
$h5-font-size: rem-calc(18) !default;
$h6-font-size: 1rem !default;

// We use these to control header size reduction on small screens
$h1-font-reduction: rem-calc(10) !default;
$h2-font-reduction: rem-calc(3) !default;
$h3-font-reduction: rem-calc(2) !default;
$h4-font-reduction: rem-calc(2) !default;
$h5-font-reduction: rem-calc(1) !default;
$h6-font-reduction: 0 !default;


// A general <small> styling
$small-font-size: 60% !default;
$small-font-color: scale-color($header-font-color, $lightness: 35%) !default;

// We use these to style paragraphs
$paragraph-font-family: inherit !default;
$paragraph-font-weight: $font-weight-normal !default;
$paragraph-font-size: 1em !default;
$paragraph-line-height: 1.4 !default;
$paragraph-margin-bottom: rem-calc(20) !default;
$paragraph-aside-font-size: rem-calc(14) !default;
$paragraph-aside-line-height: 1.35 !default;
$paragraph-aside-font-style: italic !default;
$paragraph-text-rendering: optimizeLegibility !default;


// We use these to style anchors
$anchor-text-decoration: none !default;
$anchor-text-decoration-hover: none !default;
$anchor-font-color: $alpha-link-color !default;
$anchor-font-color-hover: scale-color($anchor-font-color, $lightness: -14%) !default;

// We use these to style the <hr> element
$hr-border-width: 1px !default;
$hr-border-style: solid !default;
$hr-border-color: #ddd !default;
$hr-margin: rem-calc(20) !default;

// We use these to style lists
$list-font-family: $paragraph-font-family !default;
$list-font-size: $paragraph-font-size !default;
$list-line-height: $paragraph-line-height !default;
$list-margin-bottom: $paragraph-margin-bottom !default;
$list-style-position: outside !default;
$list-side-margin: 1.1rem !default;
$list-ordered-side-margin: 1.4rem !default;
$list-side-margin-no-bullet: 0 !default;
$list-nested-margin: rem-calc(20) !default;
$definition-list-header-weight: $font-weight-bold !default;
$definition-list-header-margin-bottom: .3rem !default;
$definition-list-margin-bottom: rem-calc(12) !default;

//
// Typography Placeholders
//

/* Default paragraph styles */
body {
    font-family: $alpha-font-family;
    font-weight: $font-weight-normal;
    color: $alpha-text-color;
    text-rendering: $paragraph-text-rendering;
}

p {
    font-family: $paragraph-font-family;
    font-weight: $paragraph-font-weight;
    font-size: $paragraph-font-size;
    line-height: $paragraph-line-height;
    //margin-bottom: $paragraph-margin-bottom;
    margin-bottom: 2vh;
    text-rendering: $paragraph-text-rendering;

    & aside {
        font-size: $paragraph-aside-font-size;
        line-height: $paragraph-aside-line-height;
        font-style: $paragraph-aside-font-style;
    }
}

/* Default header styles */
h1, h2, h3, h4, h5, h6 {
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    font-style: $header-font-style;
    color: $header-font-color;
    text-rendering: $header-text-rendering;
    margin-top: $header-top-margin;
    margin-bottom: $header-bottom-margin;
    line-height: $header-line-height;
    text-transform: uppercase;

    small {
        font-size: $small-font-size;
        //color: $small-font-color;
        line-height: 0;
    }
}

h1 { font-size: $h1-font-size - $h1-font-reduction; }
h2 { font-size: $h2-font-size - $h2-font-reduction; }
h3 { font-size: $h3-font-size - $h3-font-reduction; }
h4 { font-size: $h4-font-size - $h4-font-reduction; }
h5 { font-size: $h5-font-size - $h5-font-reduction; }
h6 { font-size: $h6-font-size - $h6-font-reduction; }

@include media-query(lap-and-up) {
    h1,h2,h3,h4,h5,h6 { line-height: $header-line-height; }
    h1 { font-size: $h1-font-size; }
    h2 { font-size: $h2-font-size; }
    h3 { font-size: $h3-font-size; }
    h4 { font-size: $h4-font-size; }
    h5 { font-size: $h5-font-size; }
    h6 { font-size: $h6-font-size; }
}

.lede {
    line-height: 1.4em;

    @include media-query(lap-and-up) {
        line-height: 1.7em;
    }
}

b, strong {
    font-weight: bold;
}
