//
//  Tools: Widths utility class
//  ---
//  Mixins to build widths to use as columns for our grid system
//

// A mixin to spit out our width classes. Pass in the columns we want the widths
// to have, and an optional suffix for responsive widths. E.g. to create thirds
// and quarters for a small breakpoint:
//
// @include widths(3 4, -sm);

// Do we want to use classes like `<div class="u-1/4">` instead of
// `<div class="u-1-of-4">`?
$use-fractions: false !default;

// Depending on what we chose for `$inuit-use-fractions`, create the relevant
// delimiter.
@if ($use-fractions == true) {
    $delimiter: \/ !global;
} @else {
    $delimiter: -of- !global;
}

@mixin widths($columns, $breakpoint: null) {
    // Loop through the number of columns for each denominator of our fractions.
    @each $denominator in $columns {

        // If we’re trying to make wholes, just spit a 100% width utility out
        // one time only.
        @if ($denominator == 1) {
            .u-1#{$delimiter}1#{$breakpoint} {
                width: 100% !important;
            }
        } @else {

            // Begin creating a numberator for our fraction up until we hit the
            // denominator.
            @for $numerator from 1 to $denominator {

                // Build a class in the format `.u-3/4` or `.u-3-of-4`.
                .u-#{$numerator}#{$delimiter}#{$denominator}#{$breakpoint} {
                    width: ($numerator / $denominator) * 100% !important;
                }

            }

        }

    }
}

// A mixin to spit out our push classes. Pass in the columns we want the widths
// to have, and an optional suffix for responsive widths. E.g. to create thirds
// and quarters for a small breakpoint:
//
// @include push(3 4, -sm);

@mixin push($columns, $breakpoint: null) {
    // Loop through the number of columns for each denominator of our fractions.
    @each $denominator in $columns {

        // If we’re trying to make wholes, just spit a 100% width utility out
        // one time only.
        @if ($denominator != 1) {

            // Begin creating a numberator for our fraction up until we hit the
            // denominator.
            @for $numerator from 1 to $denominator {

                // Build a class in the format `.u-3/4` or `.u-3-of-4`.
                .u-push-#{$numerator}#{$delimiter}#{$denominator}#{$breakpoint} {
                    margin-left: ($numerator / $denominator) * 100% !important;
                }

            }

        }

    }
}
