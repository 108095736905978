/*
    Elements: Forms
    ---
    Default markup for forms / form elements
*/

textarea {
    height: auto;
    resize: none;
}

// Placeholders

::-webkit-input-placeholder {
    font-family: $alpha-font-family;
    font-style: italic;
}

::-moz-placeholder {
    font-family: $alpha-font-family;
    font-style: italic;
}

:-ms-input-placeholder {
    font-family: $alpha-font-family;
    font-style: italic;
}
