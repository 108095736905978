/*
    Elements: Blockquotes
    ---
    Default markup for blockquotes
*/

blockquote {
    font-size: .9em;
    border-left: 3px solid #dedede;
    padding-left: 1em;
    color: #BFBFBF;
}
