//
//  Settings: breakpoints
//  ---
// Hold our breakpoint aliases and conditions in a list.
//
// These can be invoked later on via the `media-query()` mixin found in
// `_tools.responsive`.

$breakpoints: (
    "palm"            "screen and (max-width: 37.4375em)", /* 599 */
    "tablet"          "screen and (min-width: 38em) and (max-width: 49.9375em)", /*  600-799 */
    "tablet-and-down" "screen and (max-width: 49.9375em)", /*  799 */
    "lap"             "screen and (min-width: 50em) and (max-width: 63.9375em)", /*  800-1023 */
    "lap-and-up"      "screen and (min-width: 50em)", /* 800 */
    "desk"            "screen and (min-width: 64em)", /* 1024 */
    "retina"          "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)"
) !default;





// If we have included this file, set a variable to tell the rest of the
// framework that we have some responsive settings.
$responsive-settings: true !default;
