/*
    Objects: media-flag
    ---
    Default markup for media-flag
*/
/**
 * The flag object is a design pattern similar to the media object, however it
 * utilises `display: table[-cell];` to give us control over the vertical
 * alignments of the text and image. csswizardry.com/2013/05/the-flag-object
 */

// Predefine the variables below in order to alter and enable specific features.
$media-gutter:                $base-spacing-unit !default;
$enable-media-flag--rev:           false !default;
$enable-media-flag--flush:         false !default;
$enable-media-flag--top:         false !default;
$enable-media-flag--bottom:         false !default;
// $enable-media-flag--responsive:    false !default;
// $media-collapse-at:           720px !default;

/**
 * 1. Allows us to control vertical alignments
 * 2. Force the object to be the full width of its parent. Combined with [1],
 *    this makes the object behave in a quasi-`display: block;` manner.
 */
.o-media-flag,
%o-media-flag {
    display: table; /* [1] */
    width: 100%; /* [2] */
}

/**
 * Items within a flag object. There should only ever be one of each.
 *
 * 1. Default to aligning content to their middles.
 */
.o-media-flag__img,
%o-media-flag__img,
.o-media-flag__body,
%o-media-flag__body {
    display: table-cell;
    vertical-align: middle; /* [1] */
}

.o-media-flag__img,
%o-media-flag__img {
    padding-right: $media-gutter;

    img {
        display: block;
        max-width: none;
    }
}

/** The container for the main content of the flag object.
 *
 * 1. Forces the `.o-media-flag__body` to take up all remaining space.
 */
.o-media-flag__body,
%o-media-flag__body {
    width: 100%; /* [1] */

    &,
    > :last-child {
        margin-bottom: 0;
    }
}

@if ($enable-media-flag--rev == true) {

    /**
     * Reversed flag objects have their image-content to the right, and text-content
     * to the left.
     *
     * 1. Swap the rendered direction of the object…
     * 2. …and reset it.
     * 3. Reassign margins to the correct sides.
     */

    .o-media-flag--rev,
    %o-media-flag--rev {
        direction: rtl; /* [1] */

        > .o-media-flag__img,
        > %o-media-flag__img,
        > .o-media-flag__body,
        > %o-media-flag__body {
            direction: ltr; /* [2] */
        }

        > .o-media-flag__img,
        > %o-media-flag__img {
            padding-right: 0; /* [3] */
            padding-left: $media-gutter; /* [3] */
        }

    }

}

@if ($enable-media-flag--flush == true) {
    /**
     * Flush media objects have no space between the image- and text-content.
     */

    .o-media-flag--flush,
    %o-media-flag--flush {

        > .o-media-flag__img,
        > %o-media-flag__img {
            padding-right: 0;
            padding-left:  0;
        }

    }
}

@if ($enable-media-flag--top == true) {

    /**
     * Vertically top aligned flag objects.
     */

    .o-media-flag--top,
    %o-media-flag--top {

        > .o-media-flag__img,
        > %o-media-flag__img,
        > .o-media-flag__body,
        > %o-media-flag__body {
            vertical-align: top;
        }

    }

}

@if ($enable-media-flag--bottom == true) {

    /**
     * Vertically bottom aligned flag objects.
     */

    .o-media-flag--bottom,
    %o-media-flag--bottom {

        > .o-media-flag__img,
        > %o-media-flag__img,
        > .o-media-flag__body,
        > %o-media-flag__body {
            vertical-align: bottom;
        }

    }

}
