/*
    Generic: Vertical-rhythm
    ---
    Set a baseline for a consistent vertical-rhythm
*/

/**
 * Where `margin-bottom` is concerned,this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 * As per: csswizardry.com/2012/06/single-direction-margin-declarations
 */
h1, h2, h3, h4, h5, h6,
ul, ol, dl,
blockquote, p, address,
hr,
table,
fieldset, figure,
pre,
%margin-bottom {
    margin-bottom: $base-spacing-unit;
    margin-bottom: ($base-spacing-unit / $base-font-size) * 1rem;
}


/**
 * Where `margin-left` is concerned we want to try and indent certain elements
 * by a consistent amount. Define that amount once,here.
 */
ul, ol, dd,
%margin-left {
    margin-left: 2 * $base-spacing-unit;
    margin-left: (2 * $base-spacing-unit / $base-font-size) * 1rem;
}
