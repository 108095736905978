/*
    Component: Buttons
    ---
    Extend o-btn with style
*/

.btn {
    @extend %o-btn;

    // Default buttons are "outline" buttons
    background-color: $alpha-ui-color;
    color: $white;
    border: none;
    border-radius: 3px;
    padding: .375em .75em;
    transition: background-color, 100ms ease-out;

    &:focus {
        //color: $alpha-ui-color;
        //outline: none;
    }
    &:hover,
    &:active
    {
        background-color: $alpha-ui-color-dark;
        color: $white;
    }
    &:active {
        box-shadow: inset 0 2px 4px rgba(0,0,0,0.15);
    }

    &--cta {
        text-transform: uppercase;
        padding: .8em 4em;
        margin-bottom: .8em;
    }

    &--ghost {
        border: 1px solid #DCDBE7;
        background-color: transparent;
        color: $alpha-text-color;

        &:hover,
        &:active,
        &:focus {
            background-color: #f5f5f5;
            color: $alpha-text-color;
        }
    }
}
