/*
    Elements: Lists
    ---
    Default markup for lists
*/

/**
 * Remove extra vertical spacing when nesting lists.
 */
li {
    > ul,
    > ol {
        margin-bottom: 0;
    }
}

/**
 * Where `margin-left` is concerned we want to try and indent certain elements
 * by a consistent amount. Define that amount once,here.
 */
ul, ol, dd {
    margin-left: $base-font-size;
    margin-left: 1rem;
}
