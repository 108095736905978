/*
    Objects: List-inline
    ---
    Default markup for inline lists or delimited lists
*/

/**
 * The list-inline object simply displays a list of items in one line.
 */

// Predefine the variables below in order to alter and enable specific features.
$enable-list-inline--delimited:   false !default;
$list-inline-delimit-character:   ",\00A0" !default;

.o-list-inline,
%o-list-inline {
    margin:  0;
    padding: 0;
    list-style: none;

    > li {
        display: inline-block;
    }

}





@if ($enable-list-inline--delimited == true) {

    /**
     * Comma delimited list to semantically mark up lists of tags, etc.
     *
     * N.B. This component requires that you remove the whitespace between LIs.
     *      The simplest (and valid) way to achieve this is by omitting the
     *      closing `</li>` tag.
     */

    .o-list-inline--delimited,
    %o-list-inline--delimited {

        > li + li {

            &:before {
                content: "#{$list-inline-delimit-character}";
            }

        }

    }

}

