/*
    Elements: page
    ---
    Default markup for page level elements
*/
/**
 * High-, page-level styling.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 * 4. Prevent certain mobile browsers from automatically zooming fonts.
 * 5. Fonts on OSX will look more consistent with other systems that do not
 *    render text using sub-pixel anti-aliasing.
 *    NOTE: should only be used for light text on dark backgrounds: http://usabilitypost.com/2012/11/05/stop-fixing-font-smoothing/
 * 6. https://css-tricks.com/snippets/css/momentum-scrolling-on-ios-overflow-elements/
 */
html {
    font-size: ($base-font-size / 16px) * 1em; /* [1] */
    line-height: $base-line-height / $base-font-size; /* [1] */
    background-color: $base-background-color;
    color: $base-text-color;
    overflow-y: scroll; /* [2] */
    -webkit-overflow-scrolling: touch; /* [6] */
    min-height: 100%; /* [3] */
    -webkit-text-size-adjust: 100%; /* [4] */
        -ms-text-size-adjust: 100%; /* [4] */
    // -moz-osx-font-smoothing: grayscale; /* [5] */
    // -webkit-font-smoothing: antialiased; /* [5] */
}

body {
    text-rendering: optimizelegibility;
}

::selection {
    background-color: $alpha-text-color;
    color: lighten($alpha-text-color, 65%);
    text-shadow: none;
}
