//
//  Settings: colors
//  ---
// Set your colors
//
//  Defaults by https://github.com/mrmrs/colors
//  - Cool
//  - Warm
//  - Gray Scale
//  - Semantic
//

// Cool

$aqua                       : #7FDBFF !default;
$blue                       : #0074D9 !default;
$navy                       : #001F3F !default;
$teal                       : #39CCCC !default;
$green                      : #2ECC40 !default;
$olive                      : #3D9970 !default;
$lime                       : #01FF70 !default;

// Warm

$yellow                     : #FFDC00 !default;
$orange                     : #FF851B !default;
$red                        : #FF4136 !default;
$fuchsia                    : #F012BE !default;
$purple                     : #B10DC9 !default;
$maroon                     : #85144B !default;

// Gray Scale

$white                      : #fff !default;
$silver                     : #ddd !default;
$grey                       : #aaa !default;
$gray                       : $grey !default;
$black                      : #111 !default;

// Neutral colors

$alpha-neutral              : $white !default;
$beta-neutral               : $black !default;

// Text colors

$alpha-text-color           : $black !default;
$alpha-text-color-light     : mix($alpha-text-color, $alpha-neutral, 50%) !default;
$alpha-text-color-dark      : mix($alpha-text-color, $beta-neutral, 50%) !default;

$beta-text-color            : $alpha-text-color-light !default;
$beta-text-color-light      : mix($beta-text-color, $alpha-neutral, 50%) !default;
$beta-text-color-dark       : mix($beta-text-color, $beta-neutral, 50%) !default;

// Alpha colors

$alpha-ui-color             : $blue !default;
$alpha-ui-color-light       : mix($alpha-ui-color, $alpha-neutral, 50%) !default;
$alpha-ui-color-dark        : mix($alpha-ui-color, $beta-neutral, 50%) !default;

// Beta colors

$beta-ui-color              : $gray !default;
$beta-ui-color-light        : mix($beta-ui-color, $alpha-neutral, 50%) !default;
$beta-ui-color-dark         : mix($beta-ui-color, $beta-neutral, 50%) !default;

// Gamma colors

$gamma-ui-color             : $silver !default;
$gamma-ui-color-light       : mix($gamma-ui-color, $alpha-neutral, 50%) !default;
$gamma-ui-color-dark        : mix($gamma-ui-color, $beta-neutral, 50%) !default;

// Delta colors

$delta-ui-color             : $white !default;
$delta-ui-color-light       : mix($delta-ui-color, $alpha-neutral, 50%) !default;
$delta-ui-color-dark        : mix($delta-ui-color, $beta-neutral, 50%) !default;

// Notification colors

$error-color                : $red !default;
$success-color              : $green !default;
$notice-color               : $yellow !default;

// Greyscale of alpha-ui-color

$grey-00 : hsl(hue($alpha-ui-color),10,45) !default;
$gray-00 : $grey-00 !default;
$grey-01 : hsl(hue($alpha-ui-color),10,55) !default;
$gray-01 : $grey-01 !default;
$grey-02 : hsl(hue($alpha-ui-color),15,65) !default;
$gray-02 : $grey-02 !default;
$grey-03 : hsl(hue($alpha-ui-color),15,75) !default;
$gray-03 : $grey-03 !default;
$grey-04 : hsl(hue($alpha-ui-color),15,85) !default;
$gray-04 : $grey-04 !default;
$grey-05 : hsl(hue($alpha-ui-color),25,95) !default;
$gray-05 : $grey-05 !default;

// Link colors

$alpha-link-color           : $alpha-ui-color !default;
$alpha-link-colorhover      : darken($alpha-link-color, 10) !default;
$alpha-link-colorfocus      : darken($alpha-link-color, 10) !default;
$alpha-link-coloractive     : lighten($alpha-link-color, 10) !default;
$alpha-link-colorvisited    : darken($alpha-link-color, 5) !default;
