/*
    Objects: Box
    ---
    Default markup for boxed off content
*/
//$enable-box--flush:   false !default;

/**
 * 1. So we can apply the `.box` class to naturally-inline elements.
 */
.o-box,
%o-box {
    background-color: #f5f5f5;

}


@if ($enable-box--flush == true) {

    /**
     * Flush (i.e. no padding) box.
     */

    .o-box--flush,
    %o-box--flush {
        padding: 0;
    }

}
